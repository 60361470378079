import AbstractEnumType from 'common/models/enums/types/abstract-enum-type';
import TypeUnitEnum from 'common/models/enums/types/unit-enum';
import { createModelKpi, ModelKpi } from 'common/models/enums/models/kpi-enum';
import { isEqual } from 'lodash';

export default class TypeKpiEnum extends AbstractEnumType {
  public static readonly GMV = createModelKpi({
    value: 'gmv',
    keyTranslate: 'gmv',
    groupKeyTranslate: 'kpi',
    weight: 1,
    unitVolume: TypeUnitEnum.CURRENCY,
    unitProgression: TypeUnitEnum.PERCENTAGE,
  });

  public static readonly GMV_QUANTITY = createModelKpi({
    value: 'gmv_qty',
    keyTranslate: 'gmvQuantity',
    groupKeyTranslate: 'kpi',
    weight: 5,
    unitVolume: TypeUnitEnum.NONE,
    unitProgression: TypeUnitEnum.PERCENTAGE,
  });

  public static readonly MARGIN = createModelKpi({
    value: 'margin',
    keyTranslate: 'margin',
    groupKeyTranslate: 'kpi',
    weight: 2,
    unitVolume: TypeUnitEnum.CURRENCY,
    unitProgression: TypeUnitEnum.PERCENTAGE,
  });

  public static readonly TICKET = createModelKpi({
    value: 'ticket',
    keyTranslate: 'ticket',
    groupKeyTranslate: 'kpi',
    weight: 4,
    unitVolume: TypeUnitEnum.NONE,
    unitProgression: TypeUnitEnum.PERCENTAGE,
  });

  public static readonly AOV = createModelKpi({
    value: 'aov',
    urlParam: 'aov',
    keyTranslate: 'aov',
    groupKeyTranslate: 'kpi',
    weight: 6,
    unitVolume: TypeUnitEnum.CURRENCY,
    unitProgression: TypeUnitEnum.PERCENTAGE,
    showColumnsShareAmount: false,
    precision: 2,
  });

  public static readonly GMV_MARGIN_RATE = createModelKpi({
    value: 'gmv_margin_rate',
    urlParam: 'gmv_margin_rate',
    keyTranslate: 'gmvMarginRate',
    groupKeyTranslate: 'kpi',
    weight: 3,
    unitVolume: TypeUnitEnum.PERCENTAGE,
    unitProgression: TypeUnitEnum.POINTS,
    showColumnsShareAmount: false,
    precision: 2,
  });

  public static readonly AVERAGE_ORDER_QUANTITY = createModelKpi({
    value: 'average_order_quantity',
    keyTranslate: 'averageOrderQuantity',
    groupKeyTranslate: 'kpi',
    weight: 7,
    unitVolume: TypeUnitEnum.NONE,
    unitProgression: TypeUnitEnum.PERCENTAGE,
  });

  public static readonly AVERAGE_SELLING_PRICE = createModelKpi({
    value: 'average_selling_price',
    keyTranslate: 'averageSellingPrice',
    groupKeyTranslate: 'kpi',
    weight: 8,
    unitVolume: TypeUnitEnum.CURRENCY,
    unitProgression: TypeUnitEnum.PERCENTAGE,
  });

  public static readonly ALL: ModelKpi[] = [
    TypeKpiEnum.GMV,
    TypeKpiEnum.GMV_QUANTITY,
    TypeKpiEnum.MARGIN,
    TypeKpiEnum.TICKET,
    TypeKpiEnum.AOV,
    TypeKpiEnum.GMV_MARGIN_RATE,
    TypeKpiEnum.AVERAGE_ORDER_QUANTITY,
    TypeKpiEnum.AVERAGE_SELLING_PRICE,
  ];

  public static readonly ALL_CURRENCY: ModelKpi[] = TypeKpiEnum.ALL.filter(
    (e) => isEqual(e.unitVolume, TypeUnitEnum.CURRENCY),
  );

  public static byValue(
    searchValue: string,
    defaultValue?: ModelKpi,
  ): ModelKpi {
    const result = TypeKpiEnum.ALL.find((kpi) => kpi.value === searchValue);
    if (result) return result;

    return defaultValue ?? null;
  }
}
